<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'App',
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
        disableF5(e) {
            if ((e.which || e.keyCode) == 116)
            {
                e.preventDefault();
            }
        }      
    },
    mounted() {
        console.log('Mounted de App')
        if (!sessionStorage.getItem("web")){
            window.localStorage.clear();
            router.push('/')
        }
        //this.$nextTick(function(){
        //    $(document).on("keydown", this.disableF5)
        //});          
    },
    created() {
        //window.addEventListener('beforeunload', () => {
        //    window.localStorage.clear()
        //}, false)
    }, 
}
</script>






