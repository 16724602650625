import axios from "axios"

/* eslint-disable */
const globalMixin = {
    data() {
        return {

        }
    },
    methods: {
        openLoading(title) {
            Swal.fire({
                title,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        },
        checkPermisions(sRoute) {
            if(localStorage.CertIdP == undefined) {
                router.push('/')
            }
            console.log(sRoute)

            axios.get(apiURL + 'User/checkPermission?sRoute=' + sRoute + '&idRole=' + localStorage.idActualRol )
            .then( (response) => {
                if(response.data.bIsCorrect == false){
                    Swal.fire(response.data.sErrors[0])
                    window.localStorage.clear()
                    router.push('/')
                }
            } )
            .catch( (error) => {
                Swal.fire(error)
            } )





        },
        logout(){
            window.localStorage.clear()
            router.push('/')
        }

    }
}

export default globalMixin