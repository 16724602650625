import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register/',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/teacher/',
    name: 'TeacherLayout',
    component: () => import('../layouts/TeacherLayout.vue'),
    children: [
      {
        path: 'teacherhome',
        name: 'TeacherHome',
        component: () => import('../views/Teacher/Home.vue')
      },
      {
        path: 'createGroup',
        name: 'CreateGroup',
        component: () => import('../views/Teacher/CreateGroup.vue')
      },
      {
        path: 'teacherRequestInventory',
        name: 'TeacherRequestInventory',
        component: () => import('../views/Teacher/TeacherRequestInventory.vue')
      },
      {
        path: 'myGroups',
        name: 'MyGroups',
        component: () => import('../views/Teacher/MyGroups.vue')
      },
      {
        path: 'GroupDetails/:idGroup',
        name: 'GroupDetails',
        component: () => import('../views/Teacher/GroupDetails.vue'),
        props: true        
      },
      {
        path: 'masiveLoad/',
        name: 'MasiveLoad',
        component: () => import('../views/Teacher/MasiveLoad.vue'),
      },
      {
        path: 'groupReport/',
        name: 'GroupReport',
        component: () => import('../views/Teacher/GroupReport.vue'),
      },
    ]
  },
  {
    path: '/student/',
    name: 'StudentLayout',
    component: () => import('../layouts/StudentLayout.vue'),
    children: [
      {
        path: 'studenthome',
        name: 'StudentHome',
        component: () => import('../views/Student/Home.vue')
      },
      {
        path: 'myExams',
        name: 'MyExams',
        component: () => import('../views/Student/MyExams.vue')
      },
      {
        path: 'certificate/:idPersonExam/:idGroupDetail',
        name: 'Certificate',
        component: () => import('../views/Student/Certificate.vue'),
        props: true
      },
      {
        path: 'activateExam',
        name: 'ActivateExam',
        component: () => import('../views/Student/ActivateExam.vue')
      },
      {
        path: 'availableExams',
        name: 'AvailableExams',
        component: () => import('../views/Student/AvailableExams.vue')
      },  
      {
        path: 'exam',
        name: 'Exam',
        component: () => import('../views/Student/Exam.vue')
      },
      {
        path: 'results/:idPersonExam/:idGroupDetail',
        name: 'Results',
        component: () => import('../views/Student/Results.vue'),
        props: true
      },
      {
        path: 'coverPage/:idPersonExam/:idGroupDetail/:idExam',
        name: 'CoverPage',
        component: () => import('../views/Student/CoverPage.vue'),
        props: true
      },
      {
        path: 'questionsList/:idExamPool/:idResult',
        name: 'QuestionsList',
        component: () => import('../views/Student/QuestionsList.vue'),
        props: true
      },
      {
        path: 'singleQuestion/:idResultQuestion/:idResult/:idExamPool',
        name: 'SingleQuestion',
        component: () => import('../views/Student/SingleQuestion.vue'),
        props: true
      },
      {
        path: 'detailResults/:idPersonExam/:idGroupDetail',
        name: 'DetailResults',
        component: () => import('../views/Student/DetailResults.vue'),
        props: true
      },
      {
        path: 'takeExam/:sURL',
        name: 'TakeExam',
        component: () => import('../views/Student/TakeExam.vue'),
        props: true,
      },
      {
        path: 'takeExam2/:idPersonExam/:idGroupDetail',
        name: 'TakeExam2',
        component: () => import('../views/Student/TakeExam2.vue'),
        props: true,
      },
      {
        path: 'joinToGroup',
        name: 'JoinToGroup',
        component: () => import('../views/Student/JoinToGroup.vue')
      },
      {
        path: 'badges',
        name: 'Badges',
        component: () => import('../views/Student/Badges.vue')
      },
      {
        path: 'myCertificates',
        name: 'MyCertificates',
        component: () => import('../views/Student/MyCertificates.vue')
      }

           
    ]    
  },

  {
    path: '/content/',
    name: 'ContentLayout',
    component: () => import('../layouts/ContentLayout.vue'),
    children: [
      {
        path: 'contenthome',
        name: 'ContentHome',
        component: () => import('../views/AdminContent/Home.vue')
      },
      {
        path: 'createExam',
        name: 'CreateExam',
        component: () => import('../views/AdminContent/CreateExam.vue')
      }, 
      {
        path: 'exams',
        name: 'Exams',
        component: () => import('../views/AdminContent/Exams.vue')
      },
      {
        path: 'addQuestion/:idExamPool',
        name: 'AddQuestion',
        component: () => import('../views/AdminContent/AddQuestion.vue'),
        props: true,
      }, 
      {
        path: 'ExamEdith/:idExamPool',
        name: 'ExamEdith',
        component: () => import('../views/AdminContent/ExamEdith.vue'),
        props: true,
      },       
    ]
  },



  {
    path: '/pages/',
    name: 'MainLayout',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/AdminCenter/Home.vue')
      },
      {
        path: 'dashboardCenter',
        name: 'DashboardCenter',
        component: () => import('../views/AdminCenter/DashboardCenter.vue')
      },
      {
        path: 'myAdminCenters',
        name: 'MyAdminCenters',
        component: () => import('../views/AdminCenter/MyAdminCenters.vue')
      },
      {
        path: 'centers',
        name: 'Centers',
        component: () => import('../views/AdminCenter/Centers.vue')
      },
      {
        path: 'centersWithRequest',
        name: 'CentersWithRequest',
        component: () => import('../views/AdminCenter/CentersWithRequest.vue')
      },
      {
        path: 'myCentersInventory',
        name: 'MyCentersInventory',
        component: () => import('../views/AdminCenter/MyCentersInventory.vue')
      },
      {
        path: 'myInventoryRequest',
        name: 'MyInventoryRequest',
        component: () => import('../views/AdminCenter/MyInventoryRequest.vue')
      },      
      {
        path: 'adminAssign',
        name: 'AdminAssign',
        component: () => import('../views/AdminCenter/AdminAssign.vue')
      },
      {
        path: 'requestInventory',
        name: 'RequestInventory',
        component: () => import('../views/AdminCenter/RequestInventory.vue')
      },
      {
        path: 'attendInventory',
        name: 'AttendInventory',
        component: () => import('../views/AdminCenter/AttendInventory.vue')
      },
      {
        path: 'attendGroupInventory',
        name: 'AttendGroupInventory',
        component: () => import('../views/AdminCenter/AttendGroupInventory.vue')
      },
      {
        path: 'codesStore',
        name: 'CodesStore',
        component: () => import('../views/AdminCenter/CodesStore.vue')
      },
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router

