import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Swal from 'sweetalert2'
import globalMixin from './mixins/globalMixin'
import VueResource from 'vue-resource'
import VueForm from 'vue-form';
import options from './validations/validations'
import { i18n } from '@/plugins/i18n'

//import jquery from 'jquery'
//se importan los estilos de la plantilla adminLTE
window.$ = window.jQuery = require('jquery')
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'admin-lte/dist/css/adminlte.min.css'
//import '@fortawesome/fontawesome-free/css/all.min.css'
//import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css'
//import 'bootstrap/dist/js/bootstrap.min'
//import 'admin-lte/dist/js/adminlte.min'
//import 'admin-lte/dist/js/demo'
//import 'admin-lte/plugins/datatables/jquery.dataTables'
//import 'admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4'



Vue.config.productionTip = false
Vue.mixin(globalMixin)
Vue.use(VueResource);


//Validaciones
Vue.use(VueForm);
Vue.use(VueForm, options);





Vue.component('vueDropzone', vue2Dropzone)


window.axios = axios
//window.apiURL = 'http://localhost:52753/'
window.apiURL = 'https://insignewebapi.azurewebsites.net/'
window.router = router
window.Swal = Swal
window.Fire = new Vue()





new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
