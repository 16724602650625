import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const messages = {
    es: {
        login: {
            login: 'Inicio de sesión',
            userLabel: 'USUARIO',
            passwordLabel: 'CONTRASEÑA',
            btnLogin: 'Iniciar Sesión',
            CreateAccount: 'Crear una cuenta',
            notRegister: 'No estoy registrado'
        },
        register: {
            haveAccount: 'Ya tengo una cuenta, quiero',
            register: 'Registro',
            name: 'Nombre',
            surnames: 'Apellidos',
            country: 'País',
            state: 'Estado',
            city: 'Ciudad',
            zip: 'C.P.',
            birthday: 'Fecha de nacimiento',
            email : 'Correo electrónico',
            badEmail: 'Correo electrónico incorrecto',
            password: 'Contraseña',
            passConfirm: 'Confirmar contraseña',
            registerLabel: 'Registrar Cuenta'
        },
        form: {
            requireMessage: 'El {0} es requerido',
            requiredMessageP: 'Los {0} son requeridos',
            requiredMessageF: 'La {0} es requerida',
            DropDownM: 'Selecciona un {0}',
            DropDownF: 'Selecciona una {0}',
            DropDownGen: 'Selecciona una Opción'

        },
        //paginas student
        studentHome: {
            studentPanel: 'Panel del alumno',
            myDashboard: 'Mi Dashboard',
            myExams: 'Mis examenes',
            myBadges: 'Mis Insignias',
            myCertificates: 'Mis certificados',
            examActivate: 'Activar un Examen'
        },
        myExams: {
            myExams: 'Mis Examenes',
            jointGroup: 'Unirse a un Grupo',
            exam: 'Examen',
            code: 'Código',
            date: 'Fecha',
            score: 'Puntaje',
            status: 'Estado',
            result: 'Resultado',
            action: 'Acción',
            badge: 'Insignia',
            certificate: 'Certificado',
            introduce: 'Presentar',
            details: 'Detalle'
        },
        Results: {
            myresults: 'Mis Resultados',
            presentation: 'Presentación',
            date: 'Fecha',
            total_time: 'Tiempo Total',
            correct: 'Correcto',
            punctuation: 'Puntuación',
            result: 'Resultado',
            return: 'Regresar'
        },
        detailresults:{
            detailedresults: 'Resultados detallados',
            theme: 'TEMA',
            questions: 'N° PREGUNTAS',
            correct: 'CORRECTAS',
            percentage: 'PROCENTAJE'
        },
        badges:{
            mybadges: 'Mis Medallas',
            studentdashboard: 'Panel del estudiante',
            discharge: 'Descarga',
            insert: 'Insertar',           
            copy: 'Reservados todos los derechos'
        },
        mycertificates:{
            mycertificates: 'Mis Certificados',
            studentdashboard: 'Panel del Alumno',
            certificatesavailable: 'Certificados disponibles',
            test: 'Examen',
            score: 'Score',
            certificate: 'Certificado'
        },
        availablexams:{
            examsavailable: 'Exámenes disponibles',
            dashboard: 'Panel',
            welcomeuser: 'Bienvenido(a), usuario',
            following: 'Tienes los siguientes exámenes disponibles para presentar',
            selectexam: 'Selecciona un Examen',
            presentexam: 'Presentar examen'
        },
        activateexam:{
            activationexams: 'Activación de exámenes',
            studentdashboard: 'Panel del Alumno',
            activatexam: 'Activar examen',
            actianexa: 'Active un examen',
            capturecode: 'Captura tu código de activación',
            examsavailable: 'Exámenes disponibles',
            selectexa: 'Selecciona un Examen',
            activate: 'Activar'
        },
        certificate:{
            certificate: 'Certificado',
            certificatecompletion: 'Certificado de Finalización',
            documentcertif: 'Este documento certifica que',
            completedcourse: 'Ha logrado con éxito la certificación de',
            withscore: 'Con un puntaje de',
            date: 'Fecha'
        },
        jointogroup:{
            activaexam: 'Activación de exámenes',
            dashboard: 'Panel',
            joingroup: 'Unirse a un Grupo',
            joingroup2: 'Únase a un Grupo',
            capturegroupcode: 'Captura tu código de Grupo'
        },
        questionslist:{
            summaruques: 'Resumen de Preguntas',
            question: 'Pregunta',
            condition: 'Estado',
            action: 'Acción',
            questionMessage: 'Al regresar a la pregunta esta será reiniciada y deberá contestar de nuevo',
            confirmMessage: '¿Desea ir a la pregunta?',
            yesMessage: 'Si, ir a la pregunta!', 
         },
         takeExam2:{
            questionMessage: 'Hay preguntas marcadas para revisar después',
            confirmMessage: '¿Desea ir al listado para revisar las preguntas?',
            yesMessage: 'Si, ir al listado de preguntas!',
            noMessage: 'No, terminar el examen'
        },
         //paginas teacher
         teachhome:{
            teadashboard: 'Panel del Profesor',
            dashboard: 'Mi Panel',
            mygroups: 'Mis grupos',
            bulk: 'Carga masiva',
            requestinv: 'Solicitar inventario',
            myreports: 'Mis reportes',
            copy: 'Todos los derechos reservados.'
        },
        teachcreategroup:{
            reggroup: 'Registrar un Grupo',
            dashboard: 'Mi Panel',
            completgroup: 'Complete los campos para crear un grupo',
            centergroupbel: 'Centro al que pertenece el grupo',
            selecoption: 'Selecciona una opción',
            groupname: 'Nombre del Grupo',
            grouprequired: 'El Nombre del Grupo es Requerido',
            groupkey: 'Clave de Grupo',
            description: 'Descripción',
            descriptionrequired: 'La Descripción de Grupo es requerido',
            statedate: 'Fecha de inicio',
            startedaterequired: 'La Fecha inicial es requerida',
            stateend: 'Fecha Final',
            stateendrequired: 'La fecha final es requerida'
        },
        teachgroupdetails:{
            exam: 'Examen',
            assigned: 'Asignado',
            activated: 'Activado'
        },
        teachgroupreport:{
            reportgroup: 'Reportes por Grupos',
            teadashboard: 'Panel del Profesor',
            myreport: 'Mis reportes',
            choosegroup: 'Elije un Grupo',
            name: 'Nombre',
            mail: 'Correo',
            code: 'Código',
            examination: 'Examen',
            status: 'Estatus',
            score: 'Score',
            date: 'Fecha',
            result: 'Resultado',
            attempt: 'Intento',            
        },
        teachmasiveload:{
            massupload: 'Carga Masiva de usuarios',
            teadashboard: 'Panel del Profesor',
            upload: 'Carga tu archivo de excel para hacer el registro de usuarios',
            instructions: 'Instrucciones',
            ps1: '1. Descarga la plantilla de carga masiva desde ',
            ps1a: 'aquí',
            ps2: '2. Captura la información de los alumnos que se van a registrar en el Grupo',
            ps3: '3. En la celda B1 coloca el Código de Grupo al que se unirán los alumnos',
            ps4: '4. Arrastra el archivo al área de carga',
            ps5: '5. Revisa el área de log para ver el resultado del proceso',
            link: 'https://f002.backblazeb2.com/file/csb-eThinking/downloads/Insigne/LayoutInsigne.xlsx'
         },
         teachmygroups:{
            groupadmin: 'Administración de Grupos',
            creategroup: 'Crear un Grupo Nuevo',
            teadashboard: 'Panel del Profesor',
            mygroups: 'Mis grupos',
            name: 'Nombre',
            code: 'Código',
            startdate: 'Fecha Inicio',
            finaldate: 'Fecha Final',
            available: 'Inv. Disponible',
            used: 'Inv. Utilizado',
            action: 'Acción',
        },
        teareqinven:{
            requestinvgroup: 'Solicitar inventario para Grupo',
            teadashboard: 'Panel del Profesor',
            requestinv: 'Solictar Inventario',
            completecode: 'Complete los campos para solicitar códigos a su Administrador',
            groupreqinv: 'Grupo que solicita Inventario',
            select: 'Selecciona una opción',
            required: 'Numero de Códigos requeridos',
            numcodreq: 'El Numero de codigos es Requerido',
            numcero: 'Debe elegir un numero mayor que cero.',
            comments: 'Comentarios'
        },
        //paginas admin
        adminassign:{
            centeradmin: 'Administración de centros',
            searchuser: 'Buscar Usuario',
            choose: 'Elije el centro al que deseas agregar un administrador ',
            mail: 'Correo electronico',
            name: 'Nombre',
            last: 'Apellidos',
            search: 'Buscar',
            resultsearch: 'Resultados de Busqueda',
            associdcenter: 'Centros Asociados',
            actions: 'Acciones',
         },
         attendgroupInventory:{
            requestinv:'Solicitar Inventario',
            createcenter: 'Creación de Centros',
            attcenter: 'Centro que atiende',
            gropinvreq: 'Solicitudes de Inventario de grupo',
            requesteacher: 'Maestro que solicita',
            requestcomment: 'Comentarios de la solicitud',
            nummanainv: 'Numero de unidades de Inventario Atendido',
            numcodreq: 'El numero de inventario a solicitar es requerido',
            numcero: 'Debe elegir un numero mayor que cero.',
            comments: 'Comentarios',
            commentsugg: 'Se sugiere dar un comentario',
        },
        attendinventory:{
            complet: 'Complete los campos para Atender las solicitudes de inventario',
            requestcenter: 'Centro Solicitante',
            invrequest: 'Solicitudes de Inventario',
            requesadmin: 'Administrador que solicita',
            requesinvent: 'Inventario Solicitado',
            requcomment: 'Comentarios de la solicitud'
        },
        admincenters:{
            createcenter: 'Creación de Centros',
            complet: 'Complete los campos para crear un centro nuevo',
            parentcenter: 'Centro padre',
            namecenter: 'Nombre del centro',
            requestcenter: 'El nombre del centro es requerido',
            centertype: 'Tipo de centro',
            country: 'País',
            state: 'Estado',
            city: 'Ciudad',
            requestcity: 'El nombre de la ciudad es requerido'
        },
        withrequest:{
            reuestinv: 'Solicitudes de Inventario'
        },
        centersinvent:{
            invmov: 'Movimientos de Inventario'
        },

         headers:{
             dashboard: 'Mi Panel',
             centers: 'Centros',
             select: 'Selecciona una opción',            
         },
         codesstore:{
            codestore: 'Almacén de códigos',
            code: 'Códigos',
            assign: 'Asignar',
            assigned: 'Asignados',
            used: 'Usados',
            canceled: 'Cancelados',
            cancelcod: 'Cancelar códigos',
        },
        codesstoreold:{
            queryopts:'Opciones de consulta',
            codeavai: 'Códigos Disponibles',
            usecode: 'Códigos Usados',
            assigcode: 'Códigos Asignados',
            cancelcode: 'Códigos Cancelados'
        },
        dashboardcenter:{
            centermanager: 'Centros que administro',
            requestcener: 'Centros con solicitud de inventario',
            createcenter: 'Crear un Centro'
        },
        adminhome:{
            admindash:'Dashboard del administrador',
            mycenters: 'Mis Centros',
            managercentre: 'Administre y edite sus Centros',
            myinventor: 'Mis inventarios',
            consultinv: 'Consulte y revise sus inventarios',
            myrequest: 'Mis solicitudes',
            checkstatus: 'Verifique el estatus de sus solicitudes',
            codeware: 'Almacen de Códigos',
            managercode: 'Administre Los Códigos de su Centro',
            privileges: 'Conceder Privilegios',
            manegeuse: 'Convierta en Administrador o Maestro a un Usuario',
            assigcodegrup: 'Asignar inventarios a Grupos',
            assiginvcod: 'Asigne Inventario a los grupos de su Centro',
            copy: 'Todos los derechos reservados.'
        },
        myadmincenters:{
            admcenter: 'Administración de centros'
        },
        myinventreq:{
            centerinv: 'Centros con solicitud de Inventario',
            myinv: 'Mis Solicitudes de Inventario'
        },
        requestinventory:{
            commplet: 'Complete los campos para hacer una solicitud de invetario',
            reqcenter: 'Centro Solicitante'
        },
        //component
        
    
        comp:{
            
            admcenter:'Centros que dependen de los centros que administro',
            admcenter1: 'Tipo',
            admcenter2: 'Centro',
            admcenter3: 'Ciudad',
            admrequestcent: 'Centros con solicitud de inventario',
            admrequestcent1: 'Administrador',
            admrequestcent2:'Solicitud',
            admrequestcent3: 'Fecha',
            admpedrecomp: 'Mis solicitudes Pendientes',
            admpedrecomp1: 'Solicitud',
            adavainvcomp: 'Inventario por centro',
            adavainvcomp1: 'Disponible',
            adavainvcomp2: 'Asignado',
            adavainvcomp3: 'Utilizado',
            adavainvcomp4: 'Cancelado',
            codeassigcom: 'Numero de Lote',
            codeassigcom1: 'Comentarios',
            codeassigcom2: 'Fecha de Creación',
            codeassigcom3: 'Acciones',
            codusecod:'Nombre',
            codusecod1:'Correo',
            codusecod2:'Código',
            codusecod3:'Examen',
            codusecod4:'Estatus',
            codusecod5:'Score',
            codusecod6:'Fecha',
            codusecod7:'Resultado',
            codusecod8:'Intento',
            codavaila: 'Complete los campos para Asiganar un lote de Códigos',
            codavaila1: 'Códigos Disponibles',
            codavaila2: 'Numero de Códigos a Asignar',
            codavaila3: 'El numero de Códigos es requerido',
            codavaila4: 'Debe elegir un numero mayor que cero',
            codavaila5: 'Comentarios',
            codavaila6: 'Se sugiere dar un comentario',
        },
     

        //funciones generales
        msg:{
            title: '¿Desea presentar el examen de <br>',
            html: 'Estos son sus datos.',
            html1: 'Nombre',
            buttoacept: 'Si, ir al Examen!',
            buttodecli: 'No, Regresar al Panel',
            text: 'El numero de intentos de presentar el examen ha sido excedido!!!',
            code:'Código',
            activecode: 'Activando Código ...',
            datasave:'Datos Guardados correctamente',
            dataexcel: 'Clic aquí para subir datos desde Excel',
            dataexcelproce: 'Archivo Procesado Correctamente',
            generatingcertif: 'Generando Certificados. Espere por favor...',

            admmustchoose: 'Debe elegir un centro',
            admintitle: 'Desea dar privilegios de Administrador del Centro',
            adminSwal1: 'Datos Guardados Correctamente!',
            adminSwal1a: 'Guardado Datos..',
            adminSwal1aa: 'Procesando Datos..',
            adminSwal2: 'Ninguna acción realizada',
            admintitle2: '¿Desea convertir a ',
            admintitle2a: 'en maestro?',
            openLoading: 'Espere por favor...',
            textinv: 'El inventario disponible no es suficiente para procesar esa petición',
            email: 'Correo: '
            
        },
        btns:{
            btnpreexit: 'Regresar y Salir',
            btnirexam: 'Ir al Examen',
            btnjoin: 'Unirse',
            btngoquest: 'Ir a la pregunta',
            btncontinue: 'Continuar',
            btnseequest: 'Ver Preguntas',
            btnreplyaft: 'Responder Despues',
            btnnext: 'Siguiente',
            btnfinish: 'Terminar',
            btnsend: 'Enviar',
            btnexport: 'Exportar a Excel',


            btnmakemaster: 'Convertir en Maestro',
            btnadminconfirmButt: 'Asociar',
            btnadmindenyButt: 'Cancelar',
            btnadminconfirmButt2: 'Convertir',
            btnadmindenyButt2: 'No',
            btnwatch: 'Ver',
            btncreatecenter: 'Crear un centro',
        }

    },

    en: {
        login: {
            login: 'Login',
            userLabel: 'USER',
            passwordLabel: 'PASSWORD',
            btnLogin: 'Log in',
            CreateAccount: 'Create an Account',
            notRegister: 'I am not registered',
           
        },
        register: {
            haveAccount: 'I already have an account, I want',
            register: 'Register',
            name: 'Name',
            surnames: 'Surname',
            country: 'Country',
            state: 'State',
            city: 'City',
            zip: 'Zip',
            birthday: 'Birthday',
            email : 'E-mail',
            badEmail: 'Bad E-mail',
            password: 'Password',
            passConfirm: 'Confirm Password',
            registerLabel: 'Register Account'
        },
        form: {
            requireMessage: 'The {0} is required',
            requiredMessageP: 'The {0} are required',
            requiredMessageF: 'The {0} is required',
            DropDownM: 'Choose a {0}',
            DropDownF: 'Choose an {0}',
            DropDownGen: 'Choose an Option'

        },
        //paginas teacher
        studentHome: {
            studentPanel: 'Student Panel',
            myDashboard: 'My Dashboard',
            myExams: 'My Exams',
            myBadges: 'My Badges',
            myCertificates: 'My Certificates',
            examActivate: 'Exam Activate'
        },
        myExams: {
            myExams: 'My Exams',
            jointGroup: 'Join to Group',
            exam: 'Exam',
            code: 'Code',
            date: 'Date',
            score: 'Score',
            status: 'Status',
            result: 'Result',
            action: 'Action',
            badge: 'Badge',
            certificate: 'Certificate',
            introduce: 'Introduce',
            details: 'Details'
        },
        Results: {
            myresults: 'My results',
            presentation: 'Presentation',
            date: 'Date',
            total_time: 'Total Time',
            correct: 'Correct',
            punctuation: 'Score',
            result: 'Result',
            return: 'Return' 
        },
        detailresults:{
            detailedresults: 'Detailed results',
            theme: 'THEME',
            questions: 'QUESTIONS NUM',
            correct: 'CORRECT',
            percentage: 'PERCENTAGE'
        },
        badges:{
            mybadges: 'My Badges',
            studentdashboard: 'Student Dashboard',
            discharge: 'Discharge',
            insert: 'Insert',           
            copy: 'All rights reserved'
        },
        mycertificates:{
            mycertificates: 'My Certificates',
            studentdashboard: 'Student Dashboard',
            certificatesavailable: 'Certificates available',
            test: 'Test',
            score: 'Score',
            certificate: 'Certificate'
        },
        availablexams:{
            examsavailable: 'Exams available',
            dashboard: 'Dashboard',
            welcomeuser: 'Welcome, user',
            following: 'You have the following exams available to take',
            selectexam: 'Select an Exam',
            presentexam: 'To present the exam'
        },
        activateexam:{
            activationexams: 'Activate Exam',
            studentdashboard: 'Student Dashboard',
            activatexam: 'Activate Exam',
            actianexa: 'Activate an Exam',
            capturecode: 'Provide your activation code',
            examsavailable: 'Available Exams',
            selectexa: 'Select an Exam',
            activate: 'Activate',
            code:'Code'
        },
        certificate:{
            certificate: 'Certificate',
            certificatecompletion: 'Certificate of Completion',
            documentcertif: 'This document certifies that',
            completedcourse: 'You have successfully completed the course',
            withscore: 'With a score of',
            date: 'Date'
        },
        jointogroup:{
            activaexam: 'Activation of exams',
            dashboard: 'Dashboard',
            joingroup: 'Join a Group',
            joingroup2: 'Join a Group',
            capturegroupcode: 'Provide your Group code',

        },
        questionslist:{
           summaruques: 'Summary of Questions',
           question: 'Question',
           condition: 'Condition',
           action: 'Action',
           questionMessage: 'Your previous answer would be reset and you will need to answer it again.',
           confirmMessage: 'Continue?',
           yesMessage: 'Yes',            
        },
        takeExam2:{
            questionMessage: 'There are questions marked to review later',
            confirmMessage: 'Do you want to go to the list to review the questions?',
            yesMessage: 'Yes, go to the list of questions!',
            noMessage: 'No, finish the exam'
        },
        //paginas teacher
        teachhome:{
            teadashboard: 'Teacher Dashboard',
            dashboard: 'My Dashboard',
            mygroups: 'My groups',
            bulk: 'Bulk Load',
            requestinv: 'Request inventory',
            myreports: 'My reports',
            copy: 'All rights reserved.'
        },
        teachcreategroup:{
            reggroup: 'Register a Group',
            dashboard: 'My Dashboard',
            completgroup: 'Fill in the fields to create a group',
            centergroupbel: 'Center which the group belongs to',
            selecoption: 'Select an option',
            groupname: 'Group name',
            grouprequired: 'Group Name is Required',
            groupkey: 'Group Key',
            description: 'Description',
            descriptionrequired: 'Group Description is required',
            statedate: 'Start date',
            startedaterequired: 'Start Date is required',
            stateend: 'Final date',
            stateendrequired: 'End date is required'
        },
        teachgroupdetails:{
            exam: 'Exam',
            assigned: 'Assigned',
            activated: 'Activated'
        },
        teachgroupreport:{
            reportgroup: 'Group Reports',
            teadashboard: 'Teacher Dashboard',
            myreport: 'My reports',
            choosegroup: 'Choose a Group',
            name: 'Name',
            mail: 'Mail',
            code: 'Code',
            examination: 'Exam',
            status: 'Status',
            score: 'Score',
            date: 'Date',
            result: 'Result',
            attempt: 'Attempt',            
        },
        teachmasiveload:{
           massupload: 'Mass User Upload',
           teadashboard: 'Teacher Dashboard',
           upload: 'Upload your excel file to register users',
           instructions: 'Instructions',
           ps1: '1. Download the bulk upload template from ',
           ps1a: 'here',
           ps2: '2. Capture the information of the students who are going to register in the Group',
           ps3: '3. In cell B1 place the Group Code which the students will join to',
           ps4: '4. Drag the file to the upload area',
           ps5: '5. Check the log area to see the result of the process',
           link: 'https://f002.backblazeb2.com/file/csb-eThinking/downloads/Insigne/LayoutInsigneEN.xlsx'
        },
        teachmygroups:{
            groupadmin: 'Group Administration',
            creategroup: 'Create a New Group',
            teadashboard: 'Teacher Dashboard',
            mygroups: 'My groups',
            name: 'Name',
            code: 'Code',
            startdate: 'Start date',
            finaldate: 'Final date',
            available: 'Available Inv.',
            used: 'Used Inv.',
            action: 'Action',
        },
        teareqinven:{
            requestinvgroup: 'Request inventory for Group',
            teadashboard: 'Teacher Dashboard',
            requestinv: 'Request Inventory',
            completecode: 'Complete the fields to request codes from your Administrator',
            groupreqinv: 'Group Requesting Inventory',
            select: 'Select an option',
            required: 'Number of Codes required',
            numcodreq: 'The number of codes is required',
            numcero: 'You must choose a number greater than zero.',
            comments: 'Comments'
        },
        //paginas admin
        adminassign:{
           centeradmin: 'Center Administration',
           searchuser: 'Search user',
           choose: 'Choose the center you want to add an administrator to',
           mail: 'Mail',
           name: 'Name',
           last: 'Last name',
           search: 'Search',
           resultsearch: 'Search results',
           associdcenter: 'Associated Centers',
           actions: 'Actions',
        },
        attendgroupInventory:{
            requestinv:'Request Inventory',
            createcenter: 'Creation of Centers',
            attcenter: 'Center that attends',
            gropinvreq: 'Group Inventory Requests',
            requesteacher: 'Requesting Teacher',
            requestcomment: 'Request Comments',
            nummanainv: 'Number of units of Managed Inventory',
            numcodreq: 'The number of codes is required',
            numcero: 'You must choose a number greater than zero.',
            comments: 'Comments',
            commentsugg: 'Comment is suggested',

        },
        attendinventory:{
            complet: 'Complete the fields to attend inventory requests',
            requestcenter: 'Requesting Center',
            invrequest: 'Inventory Requests',
            requesadmin: 'Requesting Administrator',
            requesinvent: 'Requested Inventory',
            requcomment: 'Request Comments'
        },
        admincenters:{
            createcenter: 'Creation of Centers',
            complet: 'Complete the fields to create a new center',
            parentcenter: 'parent center',
            namecenter: 'Name of the center',
            requestcenter: 'The name of the center is required',
            centertype: 'Center Type',
            country: 'Country',
            state: 'State',
            city: 'City',
            requestcity: 'City name is required'
        },
        withrequest:{
            reuestinv: 'Inventory Requests'
        },
        codesstore:{
            codestore: 'Code Store',
            code: 'Code',
            assign: 'Assign',
            assigned: 'Assigned',
            used: 'Used',
            canceled: 'Canceled',
            cancelcod: 'Cancel Codes',
        },
        codesstoreold:{
            queryopts:'Query Options',
            codeavai: 'Available Codes',
            usecode: 'Used Codes',
            assigcode: 'Assigned Codes',
            cancelcode: 'Canceled Codes'
        },
        dashboardcenter:{
            centermanager: 'Centers that I control',
            requestcener: 'Centers with inventory request',
            createcenter: 'Create a Center'
        },
        adminhome:{
            admindash:'Admin Dashboard',
            mycenters: 'My Centers',
            managercentre: 'Manage and edit your Centers',
            myinventor: 'My Inventories',
            consultinv: 'Consult and review your inventories',
            myrequest: 'My requests',
            checkstatus: 'Check the status of your applications',
            codeware: 'Code Warehouse',
            managercode: 'Manage Your Center Codes',
            privileges: 'Grant Privileges',
            manegeuse: 'Make a User an Administrator or Teacher',
            assigcodegrup: 'Assign inventories to Groups',
            assiginvcod: 'Assign Inventory to groups in your Center',
            copy: 'All rights reserved'
        },
        myadmincenters:{
            admcenter: 'Center Administration'
        },
        centersinvent:{
            invmov: 'Inventory Movements'
        },
        myinventreq:{
            centerinv: 'Centers with inventory request',
            myinv: 'My Inventory Requests'

        },
        requestinventory:{
            commplet: 'Complete the fields to make an inventory request',
            reqcenter: 'Requesting Center'
        },

        headers:{
            dashboard: 'My Dashboard',
            centers: 'Centers',
            select: 'Select an option',           
        },
        //componentes
        comp:{
            admcenter:'Centers that depend on the centers I manage',
            admcenter1: 'Type',
            admcenter2: 'Center',
            admcenter3: 'City',
            admrequestcent: 'Centers with inventory request',
            admrequestcent1: 'Administrator',
            admrequestcent2:'Application',
            admrequestcent3: 'Date',
            admpedrecomp: 'My pending requests',
            admpedrecomp1: 'Application',
            adavainvcomp: 'Inventory by center',
            adavainvcomp1: 'Available',
            adavainvcomp2: 'Assigned',
            adavainvcomp3: 'Used',
            adavainvcomp4: 'Canceled',
            codeassigcom: 'Lot Number',
            codeassigcom1: 'Comments',
            codeassigcom2: 'Creation Date',
            codeassigcom3: 'Actions',
            codusecod:'Name',
            codusecod1:'Mail',
            codusecod2:'Code',
            codusecod3:'Exam',
            codusecod4:'Status',
            codusecod5:'Score',
            codusecod6:'Date',
            codusecod7:'Result',
            codusecod8:'He tried',
            codavaila: 'Complete the fields to Assign a batch of Codes',
            codavaila1: 'Available Codes',
            codavaila2: 'Number of Codes to Assign',
            codavaila3: 'The number of Codes is required',
            codavaila4: 'You must choose a number greater than cero.',
            codavaila5: 'Comments',
            codavaila6: 'Comment is suggested',
        },

        //funciones generales
        msg:{
            title: 'You want to take the exam <br>',
            html: 'This is your data.',
            html1: 'Name',
            buttoacept: 'Yes, go to the Exam!',
            buttodecli: 'No, Return to Dashboard',
            text: 'The number of attempts to present the exam has been exceeded!!!',
            activecode: 'Activating Code...',
            datasave:'Data Saved Successfully',
            dataexcel: 'Click here to upload data from Excel',
            dataexcelproce: 'File Processed Successfully',
            generatingcertif: 'Generating Certificates. Please wait...',

            admmustchoose: 'You must choose a center',
            admintitle: 'You want to give Center Administrator privileges',
            adminSwal1: 'Data Saved Successfully!',
            adminSwal1a: 'Saved Data...',
            adminSwal2: 'No action taken',
            admintitle2: 'want to convert to',
            admintitle2a: 'in teacher?',
            openLoading: 'Please wait...',
            adminSwal1aa: 'Processing Data..',
            textinv: 'The available inventory is not enough to process that request',
            email: 'Email: '

            
        },

        btns:{
            btnpreexit: 'Return and Exit',
            btnirexam: 'Go to Exam',
            btnjoin: 'Join',
            btngoquest: 'go to question',
            btncontinue: 'Continue',
            btnseequest: 'See Questions',
            btnreplyaft: 'Skip',
            btnnext: 'Next',
            btnfinish: 'Finish',
            btnsend: 'Send',
            btnexport: 'Export to Excel',

            btnassociate: 'Associate',
            btnmakemaster: 'Make Master',
            btnadminconfirmButt: 'Associate',
            btnadmindenyButt: 'Cancel',
            btnadminconfirmButt2: 'Convert',
            btnadmindenyButt2: 'Do not',
            btnwatch: 'See',
            btncreatecenter: 'Create a hub',
        }
    }
};

export const i18n = new VueI18n({
    locale: window.navigator.language.split('-')[0],
    //locale: 'en',
    fallbackLocale: 'es',
    messages
})
